import React from "react"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { shuffleArray, getUnrelatedReviews, getHearFromHeadingAndBlurbObject } from "../utils/utils"
import SEO from "../components/seo"

import Button from '@bit/azheng.joshua-tree.button';
import OffsetHeadingCTA from "../components/CTA/OffsetHeadingCTA"
import FixedFacewall from "../components/carousel/FixedFacewall"
import ReviewRowsSection from "../components/ReviewRowsSection"
import ImageMeta from "../components/ImageMeta"

import { Link, graphql } from "gatsby"

var showdown = require("showdown")

const ProcedureWithoutVideoPage = ({ data, pageContext }) => {

  var post
  var language
  if (pageContext && pageContext.language) {
    language = pageContext.language
    switch (language) {
      case "en":
        post = data.allProcedures.edges[0].node
        break
      case "es":
        post = data.allProceduresEs.edges[0].node
        break
      default:
        post = data.allProcedures.edges[0].node
    }
  } else {
    post = data.allProcedures.edges[0].node
  }


  var originalReviewCount;
  var relatedReviews = [];
  var allReviews = [];
  var fixedFacewallReviews = [];
  var leftoverReviews = [];

  function processFacewallReviews() {
    if (data.relatedReviews.nodes) relatedReviews = data.relatedReviews.nodes
    if (data.allReviews.nodes) allReviews = data.allReviews.nodes
    originalReviewCount = relatedReviews.length

    shuffleArray(relatedReviews)

    var unrelatedReviews = getUnrelatedReviews(
      relatedReviews,
      allReviews,
      "title"
    )

    shuffleArray(unrelatedReviews);

    // Limit leftover reviews to 5, all reviews per page to 9
    var relatedReviewsLength = relatedReviews.length
    if (relatedReviewsLength > 4 + 5) {
      relatedReviewsLength = 9
    }

    // If longer than 4, Split reviews into facewall (4) and review rows (up to 5)
    if (relatedReviews.length > 4) {
      fixedFacewallReviews = relatedReviews.slice(0, 4)
      leftoverReviews = relatedReviews.slice(4, relatedReviewsLength)
    }

    // Else, fill facewall with unrelated reviews until 4 long
    else if (fixedFacewallReviews.length < 4) {
      fixedFacewallReviews = relatedReviews
      while (fixedFacewallReviews.length < 4) {
        fixedFacewallReviews.push(unrelatedReviews.pop())
      }
    } else {
      fixedFacewallReviews = relatedReviews
    }
  }

  processFacewallReviews();

  var converter;
  var text;
  var html;
  var cta;
  var ctaHtml;

  function markdownToHtml() {
    converter = new showdown.Converter()
    text = post.body
    cta = post.ctaSection
    html = converter.makeHtml(text)

    ctaHtml = converter.makeHtml(cta)
  }

  markdownToHtml()

  function createBodyHtml() {
    return { __html: html }
  }
  function createCtaHtml() {
    return { __html: ctaHtml }
  }

  var headingAndBlurb = getHearFromHeadingAndBlurbObject(post, originalReviewCount);

  var fixedFacewallHeading = headingAndBlurb.heading;
  var fixedFacewallBlurb = headingAndBlurb.blurb;

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO title={post.metaTitle} description={post.metaDescription} schemaPage={post.schemaPage ? post.schemaPage : ""} />
        <div>
        <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={language === "es" ? "/es/procedimientos/" : "/procedures/"}
          />
          <section className="procedure section white-back joshua-tree-content nav-spacer__only-desktop">
            <div className="columns">
              <div className="column is-5"></div>
              <div
                className="column"
                dangerouslySetInnerHTML={createBodyHtml()}
              ></div>
              <div className="column is-5"></div>
            </div>
          </section>

          {fixedFacewallReviews.length >= 4 && language !== "es" && (
            <FixedFacewall
              blurb={<p>{fixedFacewallBlurb}</p>}
              heading={fixedFacewallHeading}
              sideColumnIs={1}
              middleColumnsis={1}
              reviews={fixedFacewallReviews}
            />
          )}

          {post.ctaSection && (
            <section
              className="body-sections section joshua-tree-content cta-with-button"
              style={{ paddingTop: "0" }}
            >
              <div className="columns">
                <div className="column is-5"></div>
                <div
                  className="column"
                  dangerouslySetInnerHTML={createCtaHtml()}
                ></div>
                <div className="column is-5"></div>
              </div>
              <div className="columns has-text-centered">
                <div className="column">
                  <Button buttonText="Get Started" href="/get-started" />
                </div>
              </div>
            </section>
          )}

          {leftoverReviews.length > 0 && language !== "es" && (
            <ReviewRowsSection
              reviews={leftoverReviews}
              nameSingular={post.nameSingular}
            />
          )}

          <div>
          <OffsetHeadingCTA
              headingSideColumnIs={5}
              sideColumnIs={4}
              headingJSX={
                <h3 style={{ marginBottom: "1.5rem" }}>
                  {language === "es"
                    ? "Preguntas? Estamos Aquí Para Ayudar"
                    : "Questions? We Are Here to Help"}
                </h3>
              }
              paragraphJSX={
                language === "es" ? (
                  <p className="large">
                    Nuestro personal está aquí para responder sus preguntas y
                    guiarlo a lo largo de su viaje con nuestra práctica. No dude
                    en contactarnos si tiene alguna pregunta o inquietud con
                    respecto a su tratamiento.
                  </p>
                ) : (
                  <p className="large">
                    Our staff is here to answer your questions and guide you
                    throughout your journey with our practice. Feel free to{" "}
                    <Link to="/contact-us-in-mount-pleasant-sc/">contact us</Link> if
                    you have any questions or concerns regarding your treatment.
                  </p>
                )
              }
              buttonText={language === "es"
              ? "Contáctenos"
              : "Contact Us"}
              buttonUrl={language === "es"
              ? "/es/contactenos-oklahoma-city-ok/"
              : "/contact-us-in-mount-pleasant-sc/"}
            />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query($title: String!, $procedureName: [String]) {
    allProcedures: allProceduresJson(filter: { title: { eq: $title } }) {
      edges {
        node {
          title
          name
          nameSingular
          metaTitle
          metaDescription
          hearFromXPatientsBlurb
          body
          schemaPage
        }
      }
    }
    relatedReviews: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: $procedureName } }
          }
        }
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
      }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
      }
    }
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ENG" } }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
      }
    }
  }
`

export default ProcedureWithoutVideoPage
